@import "../../assets/scss/base";

.modal_share {
    border-radius: 10px;
    padding: 20px;
    width: 500px;
    margin-top: 24px;
    margin-bottom: 24px;
    height: calc(100% - 48px);
    position: fixed;
    bottom: 0%;
    right: 0%;
    transform: translate(-5%, 0%);
    z-index: 1022;


    @include breakpoint(mobile) {
        width: 93%;
    }


    &--info {
        background-color: $PRIMARY_80;
    }

    &__header {
        @include flexbox(row, space-between, flex-start, 16px);

        margin-bottom: 16px;

        &__cross {
            transform: translateX(-65px);
            margin-bottom: 0;
        }
    }

    &__body {
        margin-bottom: 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 95%;

        &__content {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;

            &__title {
                text-align: center;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
            }

            &__copy {
                flex-direction: row-reverse;
            }

            &__box {
                margin-top: 4rem;
                width: 90%;
                &__header {
                    display: flex;
                }
            }
        }
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__secret {
            display: flex;
            font-size: 1rem;
            gap: 1rem;
            font-weight: 700;
            text-transform: uppercase;
        }
    }
}

.react-toggle {
    transform: scale(1);
}


.modal--backdrop {
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background-color: rgba($PRIMARY_20, 0.6);
    position: fixed;
    z-index: 1021;
}

