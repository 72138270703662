.login {
    &__container {
        background-color: #fff;
        border-radius: 10px;

        align-items: center;
        overflow: scroll;
        width: 100%;
        display: flex;
        height: 100%;

        @include breakpoint(laptop) {
            height: initial;
            max-width: 800px;
            flex-direction: row;
        }
    }

    &__img {
        display: none;

        @include breakpoint(laptop) {
            display: block;
        }

        img {
            max-width: 100%;
        }
    }

    &__form {
        background-color: #fff;
        padding: 1.5rem;

        //    height: 100%;
        min-height: 588px;
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;

        form {
            height: 100%;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            justify-content: space-around;
        }
    }
}

.recaptcha-resend {
    width: 100%;
    display: flex;
    justify-content: center;
}
