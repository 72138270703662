@import "../../assets/scss/base";

.DocumentPreview {
    border-radius: 0.5rem;
    background-color: $PRIMARY_70;
    margin-bottom: 1rem;
    text-transform: uppercase;
    font-weight: 700;
    padding: 0.9375rem;
    &.error {
        background-color: $ERROR_70;
        color: $PRIMARY_40;
    }

    &__header {
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: space-between;

        &__title {
            margin: 0;
            line-height: 40px;
        }
    }

    &__content {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include breakpoint(mobile) {
            flex-direction: column;
            align-items: start;
        }

        p {
            margin-bottom: 0.5rem;
        }
    }

    .label {
        font-size: 0.7rem;
        margin-bottom: 0;
    }

    &__nationality {
        font-weight: 700;
        display: flex;
        flex-direction: column;
        gap: 0.3125rem;

        @include breakpoint(mobile) {
            flex-direction: row;
        }
    }
}
