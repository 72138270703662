@import "../../assets/scss/base";

$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

.button-container {
    display: flex;
    justify-content: space-between;
    gap: 2%;
}

.parentForm {
    // border: 7px solid purple;
    @media (max-width: $breakpoint-md) {
        width: 50vw;
    }

    @media (max-width: $breakpoint-sm) {
        width: 70vw;
    }

    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .handleFormContainer {
        // border: 7px solid green;
        @media (max-width: $breakpoint-md) {
            width: 100%;
        }

        @media (max-width: $breakpoint-sm) {
            width: 100vw;
        }

        height: 80%;
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: center;

        .handleFormAndTitle {
            @media (max-width: $breakpoint-md) {
                width: 100%;
                border-top-right-radius: 1.5%;
                border-bottom-right-radius: 1.5%;
            }

            @media (max-width: $breakpoint-sm) {
                border-top-right-radius: 1.5%;
                border-bottom-right-radius: 1.5%;
            }

            border-top-left-radius: 1.5%;
            border-bottom-left-radius: 1.5%;
            background: $SECONDARY_80;
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding: 24px;

            h1 {
                font-size: 1.2rem;

                @media (max-width: $breakpoint-sm) {
                    font-size: 24px;
                }
            }

            h2 {
                font-size: 1.8rem;

                @media (max-width: $breakpoint-sm) {
                    font-size: 24px;
                }
            }

            .blue-them {
                font-family: "Rubik";
                font-style: normal;
                font-weight: 800;
                font-size: 40px;
                line-height: 48px;
                text-align: center;
                text-transform: uppercase;
                color: #1956a8;
            }

            .login-form {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                gap: 2%;

                // .button-container {
                //   // display: flex;
                //   // justify-content: space-between;

                // }

                .handleTitle {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                .form-control-login {
                    width: 53%;
                    padding: 0.5rem;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    font-size: 1rem;
                }
            }
        }

        .rightBox {
            @media (max-width: $breakpoint-md) {
                display: none;
            }

            background: $NEUTRAL_50;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100vw;

            //   border: 4px solid grey;
            border-top-right-radius: 1.5%;
            border-bottom-right-radius: 1.5%;

            .illustration-document {
                width: 100%;
            }
        }
    }
}
