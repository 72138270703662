@import "../../assets/scss/base";

.Folder_List {
    display: flex;
    align-items: center;
    align-content: flex-start;
    gap: 0.5rem;
    align-self: stretch;
    flex-wrap: wrap;

    &_item {
        display: flex;
		position: relative;
        width: 9.75rem;
        padding: 0.625rem;
        flex-direction: column;
        align-items: center;
        gap: 0.625rem;
        background-color: #fff;
        border-radius: 0.5rem;
        cursor: pointer;

        &.new {
            background-color: transparent;
        }

        &:hover {
            background-color: $PRIMARY_70;
        }

        .tag {
            align-self: flex-start;
        }

        &_title {
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
        }
    }
}
