@import "../../assets/scss/base";

.snackbar {
    display: flex;
    width: 19.375rem;
    padding: 0.625rem;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 0.625rem;
    transition: width 0.3s;
    border-radius: 0.5rem;
    border: 1px solid $PRIMARY_50;
    background: $PRIMARY_70;
    position: fixed;
    right: 2.5rem;
    bottom: 2.5rem;

    @include breakpoint(mobile) {
        width: 100%;
        position: relative;
        right: inherit;
        bottom: inherit;
    }


    &__title {
        width: 100%;
        font-size: 1rem;
        font-weight: 700;
        color: $PRIMARY_40;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
    }
}

.snackbar.expanded {
    .snackbar-content {
        display: flex;
    }
}

.snackbar-content {
    display: none;
    flex-direction: column;

    //    gap: 1rem;
    width: 100%;

    p {
        margin-bottom: 1rem;
    }

    button {
        justify-content: center;
    }
}

.expand-button {
    background: none;
    border: none;
    color: currentColor;
    font-size: 18px;
    cursor: pointer;
}
