@import "../../assets/scss/base";

.folder_card {
    position: relative;

    &_content {
        display: flex;
        width: 9.75rem;
        padding: 0.625rem;
        flex-direction: column;
        align-items: center;
        gap: 0.625rem;
        background-color: #fff;
        border-radius: 0.5rem;
        cursor: pointer;

        &.new {
            background-color: transparent;
        }

        &:hover {
            background-color: $PRIMARY_70;
        }

        .tag {
            align-self: flex-start;
        }

        &_title {
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
        }

        &_more {
            position: absolute;
            right: 0;
            top: 0;
            z-index: 100;
        }
    }
}
