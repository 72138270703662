.NationalityBadge {
    display: flex;

    //  gap: 0.25rem;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 0.8125rem;
}
