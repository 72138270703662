.VerifyResult {
    &.card {
        height: calc(100vh - 6rem);
        overflow-y: auto;
    }

    &__checks {
        display: flex;
        flex-direction: column;
        gap: 0.625rem;
        flex: 1 0 0;
        align-self: stretch;
    }
}
