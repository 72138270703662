@import "../../assets/scss/base";

.FileUploader {
    border-radius: 0.5rem;
    border: 2px dashed $PRIMARY_60;
    display: flex;
    padding: 1.25rem;
    flex-direction: column;
    align-items: center;
    gap: 0.625rem;
    align-self: stretch;
    position: relative;
    margin-bottom: .5rem;
    justify-content: center;
    min-width: 8.53rem;

    @include breakpoint(mobile) {
        flex-direction: row;

        svg {
            width: 25px;
        }
    }


    input {
        cursor: pointer;
        position: absolute;
        opacity: 0;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
    }
}

.error {
    color: $ERROR_40;
    font-weight: 500;
}

.FileUploaded {
    display: flex;
    padding: 1rem;
    align-items: center;
    gap: .625rem;
    border-radius: 0.5rem;
    background-color: $PRIMARY_70;
    color: $PRIMARY_40;
    font-weight: 500;
    margin-bottom: .5rem;

    &__name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 80%;
    }

    .iconDelete {
        cursor: pointer;
        margin-left: auto;
    }
}
