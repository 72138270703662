//@import "./normalize";
@import "./base.scss";

// Atomic design
@import "./atoms/01_atoms";

//Organisms
@import "./organisms/01_organisms";

@import "./views/Login.scss";
@import "./views/Verify.scss";
@import "./views/AddFolder.scss";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 16px;
}

body {
    font-family: $font-family-body;
    font-size: 1rem;
    background: linear-gradient(278.49deg, $SECONDARY_40 0%, $PRIMARY_40 100%) no-repeat center center fixed;
    color: $PRIMARY_40;

    &.professionnels {
        background: linear-gradient(315deg, #010534 0%, #171C60 100%) no-repeat center center fixed;
        color: $SECONDARY_40;
    }
}

a {
    color: $SECONDARY_40;
}

img {
    max-width: 100%;
}

p {
    line-height: 1.625rem;
}

ol {
    padding-left: 2rem;
    margin-bottom: 1rem;
    list-style: none;
    counter-reset: line;

    li {
        position: relative;
        margin-bottom: 1rem;

        &:before {
            font-family: $font-family-numbers;
            font-size: 0.875rem;
            position: absolute;
            left: -2rem;
            top: 2px;
            display: inline-block;
            width: 22px;
            height: 22px;
            margin-right: 0.5em;
            background-color: $PRIMARY_40;
            border-radius: 50%;
            color: #fff;
            text-align: center;
            line-height: 22px;
            counter-increment: line;
            content: counter(line);
        }
    }
}

hr {
    border: 1px solid $PRIMARY_60;
    margin: 1rem 0;
}

.digit {
    font-family: $font-family-numbers;

    &-S {
        font-size: 0.625rem;
    }

    &-M {
        font-size: 0.875rem;
    }

    &-L {
        font-size: 1rem;
    }
}
