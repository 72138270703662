@import "../../assets/scss/base";

.partners_card {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.6rem;
    border-bottom: 1px solid $PRIMARY_70;

    &_first {
        display: flex;
        gap: 1rem;
        align-items: center;
    }

    &_second {
        position: relative;
        display: flex;
        align-items: center;
        width: 25%;
        gap: 0.5rem;

        &_select {
            background-color: $PRIMARY_70 !important;
            border: initial !important;
            margin-bottom: 0px !important;
        }

        &_more {
            position: absolute;
            bottom: -20px;
            right: 7px;
        }
    }

}