.AddFolder {
    &__row {
        height: calc(100% - 50px);
        align-items: center;
    }

    &__illu {
        display: grid;
        place-items: center;
    }
}
