@import "../../assets/scss/base";

.react-toggle {
    &-track {
        background-color: $NEUTRAL_50;
    }

    &-thumb {
        border-color: $NEUTRAL_50;
    }

    &:hover:not(.react-toggle--disabled) .react-toggle-track {
        background-color: $NEUTRAL_40;
    }

    &:hover:not(.react-toggle--disabled) .react-toggle-thumb {
        border-color: $NEUTRAL_40;
    }

    &--checked {
        .react-toggle-track {
            background-color: $PRIMARY_40;
        }

        .react-toggle-thumb {
            border-color: $PRIMARY_40;
        }

        &:hover:not(.react-toggle--disabled) .react-toggle-track {
            background-color: $PRIMARY_30;
        }

        &:hover:not(.react-toggle--disabled) .react-toggle-thumb {
            border-color: $PRIMARY_30;
        }
    }
}
