$font-family-body: "Raleway";
$font-family-heading: "Rubik";
$font-family-numbers: "Rubik";

@font-face {
    font-family: $font-family-body;
    src: url(../fonts/Raleway-Medium.ttf) format("truetype"), url(../fonts/Raleway-Medium.woff) format("woff"), url(../fonts/Raleway-Medium.woff2) format("woff2");
    font-weight: normal;
}

@font-face {
    font-family: $font-family-body;
    src: url(../fonts/Raleway-Bold.ttf) format("truetype"), url(../fonts/Raleway-Bold.woff) format("woff"), url(../fonts/Raleway-Bold.woff2) format("woff2");
    font-weight: bold;
}

@font-face {
    font-family: $font-family-heading;
    src: url(../fonts/Rubik-Regular.ttf) format("truetype"), url(../fonts/Rubik-Bold.woff) format("woff"), url(../fonts/Rubik-Bold.woff2) format("woff2");
    font-weight: 400;
}

@font-face {
    font-family: $font-family-heading;
    src: url(../fonts/Rubik-Medium.ttf) format("truetype"), url(../fonts/Rubik-Medium.woff) format("woff"), url(../fonts/Rubik-Medium.woff2) format("woff2");
    font-weight: 500;
}

@font-face {
    font-family: $font-family-heading;
    src: url(../fonts/Rubik-Bold.ttf) format("truetype"), url(../fonts/Rubik-Bold.woff) format("woff"), url(../fonts/Rubik-Bold.woff2) format("woff2");
    font-weight: 700;
}

@font-face {
    font-family: $font-family-heading;
    src: url(../fonts/Rubik-ExtraBold.ttf) format("truetype"), url(../fonts/Rubik-ExtraBold.woff) format("woff"), url(../fonts/Rubik-ExtraBold.woff2) format("woff2");
    font-weight: 800;
}

.font-XS {
    font-size: 0.625rem;
}

.font-S {
    font-size: 0.75rem;
}
