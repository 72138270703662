@import "../../assets/scss/base";

.File {
    display: flex;
    padding: 0.625rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;
    border-radius: 0.5rem;
    border: 2px solid $PRIMARY_60;
    width: 8.53rem;
    background-color: #fff;
    position: relative;

    &__thumbnail {
        width: 100%;
        height: 9.375rem;
        position: relative;

        &__placeholder,
        &__doc {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: $PRIMARY_70;
            border-radius: 0.5rem;
            gap: 0.5rem;

            &__title {
                text-transform: uppercase;
                font-size: 1rem;
                font-weight: 700;
                color: $PRIMARY_60;
            }
        }
    }

    &__title {
        //ellipsis
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
    }

    &_more {
        position: absolute;
        right: -4px;
        top: -4px;
        z-index: 1000;
    }
}
