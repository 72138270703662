@import "../../assets/scss/base";

.MoneyDisplay {
  .number {
    font-family: $font-family-numbers;
    font-size: $LARGE_XXL;
    font-weight: bolder;
    margin-right: 3px;
    @include gradient-text-money();
  }
  .devise {
    @include gradient-text-money();
    font-family: $font-family-numbers;
    font-weight: bolder;
    font-size: $LARGE;
  }
  .description {
    height: 28px;
  }
} 
