@import "../../assets/scss/base";

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    margin: 1rem 0;
    text-align: center;

    $offset: 187;
    $duration: 1.4s;

    .spinner {
        animation: rotator $duration linear infinite;
    }

    @keyframes rotator {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(270deg);
        }
    }

    .path {
        stroke-dasharray: $offset;
        stroke-dashoffset: 0;
        transform-origin: center;
        animation: dash $duration ease-in-out infinite, colors ($duration * 4) ease-in-out infinite;
    }

    @keyframes colors {
        0% {
            stroke: $PRIMARY_40;
        }

        25% {
            stroke: $PRIMARY_50;
        }

        50% {
            stroke: $PRIMARY_60;
        }

        75% {
            stroke: $PRIMARY_50;
        }

        100% {
            stroke: $PRIMARY_40;
        }
    }

    @keyframes dash {
        0% {
            stroke-dashoffset: $offset;
        }

        50% {
            stroke-dashoffset: $offset / 4;
            transform: rotate(135deg);
        }

        100% {
            stroke-dashoffset: $offset;
            transform: rotate(450deg);
        }
    }
}
