.DocsPerOrganisms {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    position: relative;

    &__list {
        display: flex;
        gap: 1.25rem;
    }

    &__organism {
        margin-bottom: 1rem;

        &__header {
            display: flex;
            align-items: center;
            gap: 0.625rem;
            margin-bottom: .5rem;

            &__img {
                border-radius: 0.5rem;
                background: #fff;

                img {
                    width: 1.75rem;
                }
            }

            &__title {
                margin-bottom: 0;
            }
        }

        &__documents {
            border-radius: 0.625rem;
            background: #FFF;
            display: flex;
            padding: 0.625rem;
            align-items: flex-start;
            gap: 0.625rem;
        }
    }
}

.scroll-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.875rem;
    align-self: stretch;
    width: 100%;
    margin-bottom: 1.5rem;
}
