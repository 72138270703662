@import "../../assets/scss/base";

.input-box {
    position: relative;

    &.error {
        input {
            border-color: $ERROR_40;
        }

        label {
            color: $ERROR_40 !important;
        }
    }

    &.success {
        input {
            border-color: $SUCCESS_40;
        }

        label {
            color: $SUCCESS_40;
        }
    }

    input {
        background: #ffffff;
        border: 1px solid $PRIMARY_40;
        border-radius: 5px;
        padding: 8px 12px;
        height: 42px;
        margin-bottom: 0.5rem;
        color: $PRIMARY_40;
        font-size: 1rem;
        width: 100%;
        appearance: none;
        transition: 0.15s ease-in-out;

        &:focus,
        &:valid,
        &:autofill,
        &:active,
        &:not(:placeholder-shown) {
            margin-top: 1rem;
        }

        &:focus ~ label,
        &:valid ~ label,
        &:autofill ~ label,
        &:active ~ label,
        &:not(:placeholder-shown) ~ label {
            top: -1rem;
            left: 0;
            color: $PRIMARY_40;
            font-size: 0.875rem;
        }
    }

    label {
        color: $PRIMARY_50;
        position: absolute;
        top: 0;
        left: 0;
        padding: 10px 12px;
        pointer-events: none;
        transition: 0.5s;
        font-size: 1rem;
    }

    .error-message {
        color: $ERROR_40;
        font-size: 0.875rem;
        margin-top: 0.5rem;
    }
}
