body {
    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6 {
        font-family: $font-family-heading;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 1rem;
    }

    h1,
    .h1 {
        font-size: 3.75rem;
        font-weight: 800;
    }

    h2,
    .h2 {
        font-size: 2.5rem;
        font-weight: 800;
    }

    h3,
    .h3 {
        font-size: 1.5rem;
    }

    h4,
    .h4 {
        font-size: 1rem;
    }

    h5,
    .h5 {
        font-size: 0.8125rem;
        font-weight: 400;
    }
}
