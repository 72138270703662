@import "../../assets/scss/base";

.skeleton {
    background-color: $PRIMARY_70;

    // animation: 1.5s ease-in-out 0.5s infinite normal none running skeleton-wave;
    transform-origin: 0px 55%;
    transform: scale(1, 0.6);
    overflow: hidden;
    position: relative;
    height: 1.5rem;
    margin-bottom: .625rem;
    display: block;
    border-radius: 4px / 6.7px;

    &::after {
        animation: 1.6s linear 0.5s infinite normal none running skeleton-wave;
        background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent);
        content: "";
        position: absolute;
        transform: translateX(-100%);
        inset: 0px;
    }
}

@keyframes skeleton-wave {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(100%);
    }
}
