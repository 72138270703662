button {
    border: none;
    background-color: transparent;
    cursor: pointer;
}

a.btn:not(.btn-link) {
    display: inline-block;
    text-decoration: none;
}

.btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 24px;
    gap: 8px;
    border-radius: 8px;
    border: none;
    text-transform: uppercase;
    letter-spacing: .5px;
    font-weight: 700;
    font-family: $font-family-body;
    transition-duration: .5s;
    cursor: pointer;

    .loader {
        margin: 0;
    }

    &:disabled, &:disabled.btn-solid {
        background-color: $PRIMARY_70;
        color: $PRIMARY_60;
    }

    &-solid {
        color: #fff;
    }

    // Primary
    &-solid.btn-primary {
        background-color: $PRIMARY_40;

        &:hover:not(:disabled) {
            background-color: $PRIMARY_20;
        }
    }

    &-solid.btn-white {
        background-color: #fff;
        color: $PRIMARY_40;

        &:hover:not(:disabled) {
            background-color: $PRIMARY_40;
            color: #fff;

            svg {
                fill: #fff;
            }
        }
    }

    &-outlined.btn-primary {
        background-color: transparent;
        border: 1px solid $PRIMARY_40;
        color: $PRIMARY_40;

        &:hover {
            background-color: $PRIMARY_70;
        }

        &:disabled {
            background-color: transparent;
            border-color: $PRIMARY_50;
        }
    }

    // Secondary
    &-solid.btn-secondary {
        background-color: $SECONDARY_40;

        &:hover {
            background-color: $SECONDARY_20;
        }
    }

    &-outlined.btn-secondary {
        background-color: transparent;
        border: 1px solid $SECONDARY_40;
        color: $SECONDARY_40;

        &:hover {
            background-color: $SECONDARY_70;
        }

        &:disabled {
            background-color: transparent;
            border: 1px solid $SECONDARY_60 !important;
            color: $SECONDARY_60 !important;
        }

        &:disabled:hover {
            background-color: $SECONDARY_70 !important;
        }
    }

    // Error

    &-solid.btn-error {
        background-color: $ERROR_40;

        &:hover {
            background-color: $ERROR_20;
        }
    }

    &-outlined.btn-error {
        background-color: transparent;
        border: 1px solid $ERROR_40;
        color: $ERROR_40;

        &:hover {
            background-color: $ERROR_70;
        }

        &:disabled {
            background-color: transparent;
            border-color: $ERROR_60;
        }
    }

    // Success

    &-solid.btn-success {
        background-color: $SUCCESS_30;

        &:hover {
            background-color: $SUCCESS_20;
        }
    }

    &-outlined.btn-success {
        background-color: transparent;
        border: 1px solid $SUCCESS_30;
        color: $SUCCESS_40;

        &:hover {
            background-color: $SUCCESS_70;
        }

        &:disabled {
            background-color: transparent;
            border-color: $SUCCESS_60;
        }
    }

    // Label

    &-label {
        background-color: transparent;
        border: none;
        color: $PRIMARY_40;

        &:hover {
            color: $SECONDARY_20;
        }

        &:disabled {
            color: $PRIMARY_60;
        }
    }

    &-link.btn {
        background-color: transparent;
        border: none;
        color: $PRIMARY_40;
        text-transform: initial;
        text-decoration: underline;

        &:hover {
            color: $SECONDARY_20;
        }

        &:disabled {
            color: $PRIMARY_60;
        }
    }

    // Link
    &-link-external {
        background-color: transparent;
        text-decoration: none;
        border-bottom: 1px solid $SECONDARY_40;
        border-radius: 0;
        position: relative;
        padding: 0 1.5rem 10px 0;
        display: block;
        text-transform: uppercase;

        &:after {
            content: "";
            position: absolute;
            width: 1rem;
            height: 1rem;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMSAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcuNTk4NDYgMi4zMTc2N0wxMC4yNDc0IDUuMTQzMjJDMTAuNDMxOCA1LjMzOTg3IDEwLjQzMTggNS42NTg3IDEwLjI0NzQgNS44NTUzNUw3LjU5ODQ2IDguNjgwOUM3LjQwNjA0IDguODg2MTUgNy4wODAyNCA4Ljg4NjE1IDYuODg3ODIgOC42ODA5QzYuNzEyMTggOC40OTM1NSA2LjcxMjE4IDguMjAyMDIgNi44ODc4MiA4LjAxNDY3TDguNzczOTIgNi4wMDI4M0wwLjUwMzU0OCA2LjAwMjgzQzAuMjI1NDQ2IDYuMDAyODMgLTMuMTQ2MThlLTA3IDUuNzc3MzkgLTIuOTE4MjVlLTA3IDUuNDk5MjlDLTIuNjkwMzJlLTA3IDUuMjIxMTggMC4yMjU0NDUgNC45OTU3NCAwLjUwMzU0OCA0Ljk5NTc0TDguNzczOTIgNC45OTU3NEw2Ljg4NzgyIDIuOTgzOUM2LjcxMjE4IDIuNzk2NTUgNi43MTIxOCAyLjUwNTAyIDYuODg3ODIgMi4zMTc2N0M3LjA4MDI0IDIuMTEyNDIgNy40MDYwNCAyLjExMjQyIDcuNTk4NDYgMi4zMTc2N1oiIGZpbGw9IiMxNzFDNjAiLz4KPC9zdmc+Cg==");
            background-size: contain;
            right: 0;
            top: 0.3rem;
        }

        &:hover:after {
            display: none;
        }
    }

    // Size
    &-S {
        font-size: .75rem;
        line-height: 18px;
        padding: 5px 12px;
        height: 24px;
    }

    &-M {
        font-size: .875rem;
        line-height: 18px;
        height: 32px;
    }

    &-L {
        font-size: 1rem;
        line-height: 20px;
    }
}
