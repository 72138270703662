@import "../../assets/scss/base";

.accordion {
    margin-bottom: 8px;

    &__title {
        font-weight: 700;
        line-height: 1.625rem;
        color: $PRIMARY_30;
    }

    &__header {
        display: flex;
        padding: .5rem 1rem;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        background-color: $PRIMARY_70;
        border-radius: 8px;
        cursor: pointer;

        &.expanded {
            border-radius: 8px 8px 0 0;
        }
    }

    &__content {
        padding: 1rem 1rem 1rem 2rem;
        background-color: #fff;
        border-radius: 0 0 8px 8px;
    }

    &__icon.expanded {
        svg {
            transform: rotate(180deg);
        }
    }
}
