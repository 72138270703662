@import "../../assets/scss/base";

.FoldersList {
    width: 100%;
    margin-bottom: 2rem;

    th {
        text-align: left;
        color: $NEUTRAL_30;
        text-transform: uppercase;
        font-size: 0.875rem;
    }

    tbody {
        tr {
            border-bottom: 1px solid $PRIMARY_70;

            td {
                padding: .5rem 0;
            }
        }
    }

    &__item__title {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 95%;
    }

    &__item__progress {
        display: flex;
        gap: .5rem;
        align-items: center;

        .progress-bar {
            margin-top: 0;
        }

        span:not(.text-primary) {
            color: $PRIMARY_60;
        }

        & > * {
            flex: 1;
        }
    }

    &__item__shared {
        display: flex;
        gap: .5rem;
        align-items: center;
    }

    .react-toggle {
        transform: scale(0.85);
    }
}
