@import "../../assets/scss/base";

.ResultAfterPayment {
  padding-top: 20px;
  .title {
    width: 90%;
    font-size: $LARGE_XL;
  }
  .message {
    width: 90%;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
} 
