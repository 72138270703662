@import "../../assets/scss/base";

.ConfirmPhone {
    /* Ajoutez vos styles ici */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    min-height: 588px;
    text-align: center;
    height: 100%;

    &__wrongCode {
        color: $ERROR_40;

        p {
            font-size: 18px;
        }

        h1 {
            font-size: 18px;
        }
    }

    &__inputs {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
        margin-bottom: 1rem;

        input {
            width: 3rem;
            height: 3rem;
            border-radius: 0.3125rem;
            border: 2px solid $PRIMARY_70;
            text-align: center;
            font-size: 2rem;
            font-weight: 700;
            color: $PRIMARY_50;
            font-family: 'Roboto', sans-serif;
        }
    }
}
