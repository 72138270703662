@import "../../assets/scss/base";

.ProfilCard {
    background-color: white;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &__header {
        display: flex;
        background: $PRIMARY_70;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        color: $PRIMARY_40;

        h3, h4 {
            margin-bottom: 0;
        }

        h3 {
            flex-grow: 3;
        }

        &__title {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px;
            gap: 20px;
            flex-grow: 2;

            img {
                width: 36.68px;
                height: 35.13px;
            }
        }
    }

    &__body {
        padding: 0.9375rem;

        .isVerified {
            font-family: $font-family-body;
            font-weight: normal;
            text-transform: initial;
            font-size: 1rem;
        }

        .profil_picture {
            display: grid;
            place-items: center;

            .photo {
                width: 150px;
                height: 150px;
                margin: 0 auto 0.625rem;
                display: block;
            }
        }

        .btn_r {
            display: flex;
            justify-content: center;
        }
    }

    &__buttons {
        @include breakpoint(mobile) {
            flex-wrap: wrap;

            .btn {
                width: 100%;
                text-align: center;
                justify-content: center;
            }
        }
    }
}
