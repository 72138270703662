@import "../../assets/scss/base";

.hug {
    background-color: #fff;
    flex-direction: column;
    border-radius: 0.5rem;
    min-width: 100%;
    border-radius: 8px;
    position: absolute;
    right: -155px;
    top: -2px;
    z-index: 1110;
    -webkit-box-shadow: 0px 16px 32px rgba(25, 86, 168, 0.1);
    box-shadow: 0px 16px 32px rgba(25, 86, 168, 0.1);
    overflow: hidden;

    &__list {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;

        * {
            flex: 0 0 auto;
        }

        &__item {
            display: flex;
            gap: 0.5rem;
            align-items: center;
            cursor: pointer;
            padding: 0.5rem 1rem;

            &:hover {
                background-color: $PRIMARY_70;
            }
        }
    }

    &_share {
        flex-direction: row-reverse;
        justify-content: flex-end;

        &:hover {
            flex-direction: row;
        }
    }

    &_secret {
        border-radius: 0px;
        flex-direction: row-reverse;
        justify-content: flex-end;

        &:hover {
            flex-direction: row;
        }
    }

    &_delete {
        flex-direction: row-reverse;
        justify-content: flex-end;

        &:hover {
            flex-direction: row;
        }
    }
}
