@import "../../assets/scss/base";

.notification {
    padding: 10px 16px 10px 31px;
    border-radius: 0.5rem;
    background-color: #fff;
    margin-bottom: 0.625rem;
    position: relative;
    cursor: pointer;
    list-style: none;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        gap: .5rem;
        margin-bottom: 0.5rem;

        .icon {
            transition-duration: .1s;

            &.rotate {
                transform: rotate(180deg);
            }
        }
    }

    &__date {
        color: $PRIMARY_50;
        margin-bottom: 0.3125rem;
    }

    &__title {
        font-weight: 400;
        color: $PRIMARY_40;
        margin-bottom: 0.3125rem;
    }

    &__initial {
        color: $PRIMARY_20;
        font-weight: 500;
    }

    &__detail {
        color: $PRIMARY_20;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 500;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    &__detail.opened {
        color: $PRIMARY_20;
        font-weight: 500;
        -webkit-line-clamp: unset;
        height: auto;
    }

    &.isRead {
        background: transparent;
    }

    &__caret {
        z-index: 10;
        width: 100%;
        display: flex;
        justify-content: center;
        &_container {
            padding: 0.3rem;
        }
    }

    .notificationDot {
        position: absolute;
        left: .5rem;
        top: 4rem;
    }
}

.icon {
    transition: transform 0.3s ease;

    &.rotate {
        transform: rotate(180deg);
    }
}