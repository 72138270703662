.DocumentStatus {
    display: flex;
    gap: .5rem;
    align-items: center;
    margin-bottom: 1rem;
    padding: 0 0.625rem;
    border-radius: .5rem;
    transform: translateX(-0.625rem);

    .loader {
        margin: 0;
    }

    span {
        flex: 1;
    }
}
