@import "../../assets/scss/base";

.tabs {
    padding-top: 0.93rem;
    .tab__list {
        @include flexbox(row, flex-start, center);

        border-bottom: 2px solid $PRIMARY_70;

        &__item {
            cursor: pointer;
            padding: 10px 16px;
            border-radius: 8px 8px 0px 0px;
            color: $PRIMARY_50;
            text-transform: uppercase;
            font-family: 'Raleway';
            font-weight: 700;
            font-size: 0.875rem;
            line-height: 18px;
            display: flex;
            align-items: center;
            gap: 10px;

            &.active {
                color: $PRIMARY_40;
                background-color: $PRIMARY_70;
                border-bottom: 2px solid $PRIMARY_40;
            }
        }
    }

    .tab__content {
        padding-top: 16px;

        .tab__pane {
            display: none;

            &.active {
                display: block;
            }
        }
    }
}
