@import "../../assets/scss/base";

.PriceCard {
    background-color: white;
    border-radius: 10px;
    min-width: 200px;
    max-width: 100%;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 1.25rem;
    box-shadow: 0px 16px 32px 0px rgba(25, 86, 168, 0.1);

    &__header {
        img {
            width: 64px;
            height: 64px;
        }

        h2 {
            font-weight: 800;
            font-size: 20px;
            padding-bottom: 20px;
        }

        h3 {
            font-weight: 800;
            font-size: 60px;
            @include gradient-text-money();
        }

        .pl {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding-bottom: 20px;
        }
    }

    .text {
        text-transform: initial;
        background-color: $SECONDARY_70;
        color: #323030;
        white-space: pre-wrap;
        word-wrap: break-word;
        border-radius: 10px;
        padding: 2px 6px 2px 6px;
        min-height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .save-money {
            color: $SUCCESS_30;
        }
        .toggle {
            cursor: pointer;
        }
        .toggle:hover {
            color: $ACCENT_40;
        }
    }
    .text:hover {
        transition: .2s ease-out;
    }
    .savoir-plus:hover {
        color: $ACCENT_40;
        cursor: pointer;
    }

    &__body {
        p {
            margin-bottom: 20px;
        }
    }
}
