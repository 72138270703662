@import "../../assets/scss/base";

.AddDocumentInfos.card {
    overflow-y: auto;

    @include breakpoint(mobile) {
        border-radius: 0;
        height: 100%;
    }
}

.AddDocumentInfos {
    &__form {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-column-gap: 16px;
        grid-row-gap: 8px;
    }
}

.AddDocumentInfos__btns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.625rem;
    justify-content: center;
    align-items: center;
}
