@import "../../assets/scss/base";

.addDocumentForm.card {
    height: calc(100vh - 13rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;

    .addDocumentForm__buttons {
        margin-bottom: 1rem;
    }

    .btn {
        align-self: end;
    }
}
