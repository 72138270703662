@import "../../assets/scss/base";

.PageHeader {
    background-color: #fff;
    display: flex;
    padding: 10px 24px;
    align-items: center;
    gap: 0.625rem;
    align-self: stretch;

    h5 {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }

    .h3 {
        font-size: 24px;
        font-family: Rubik;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
        text-transform: uppercase;
    }

    * {
        margin-bottom: 0;
    }

    .PageHeader__icon {
        cursor: pointer;
        transform: scale(0.8);
    }
}
