@import "../../assets/scss/base";

.tooltip {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    margin-bottom: 2rem;

    &_container_icon {
        flex: none;
        width: 48px;
        height: 48px;
        background-color: $NEUTRAL_100;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &_container_tools {
        display: flex;
        align-items: center;
        gap: 0.6rem;
        background-color: $NEUTRAL_100;

        border-radius: 12px;
        padding: 1rem;
        flex-wrap: wrap;
        @include breakpoint(desktop) {
            padding: 0 1rem;
            height: 48px;
        }
        //width: 100%;

        &_divider {
            width: 1px;
            height: 26px;
            background-color: $SECONDARY_70;
            &.first {
                display: none;
                @include breakpoint(laptop) {
                    display: block;
                }
            }
        }
    }
}

.react-toggle {
    transform: scale(0.75);
}
