@import "../../assets/scss/base";

.thumbnail-pdf {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 3.65rem);
    margin-bottom: 1rem;
}

.ImageFilter {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .thumbnail {
        width: 100%;
        height: 100%;
        aspect-ratio: 1/1;
        border-radius: 1rem;
        overflow: hidden;
        border: 5px solid $PRIMARY_70;
        margin-bottom: 1rem;

        canvas {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
