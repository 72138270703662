@import "../../assets/scss/base";

.SidebarSlide {
    background-color: rgba($color: #000000, $alpha: 0.25);
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    z-index: 10;

    &__content {
        border-radius: 0.625rem;
        background: $PRIMARY_80;
        width: 25rem;
        padding: 1.25rem;
        height: 100%;
        animation-duration: .5s;
        animation-name: slidein;
        position: relative;

        &__close {
            position: absolute;
            top: 1rem;
            right: 1rem;
            cursor: pointer;
            z-index: 11;
        }
    }
}

@keyframes slidein {
    from {
        margin-left: -100%;
    }

    to {
        margin-left: 0;
    }
}
