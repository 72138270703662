@import "../../assets/scss/base";

.home {
    background: $PRIMARY_70;
    display: grid;
    align-items: center;
    background-image: url(../../assets/img/home_bg.png);
    background-repeat: no-repeat;
    background-position: 100% 0;
    min-height: 100vh;
    // @include breakpoint(laptop) {
    //     height: 100vh;
    // }

    &.container {
        height: 31rem;
        align-items: flex-end;
    }

    &__content {
        max-width: 800px;
        margin: 0 auto;
    }

    &__title {
        font-style: normal;
        font-weight: 800;
        background: var(--gradient-1, linear-gradient(315deg, #1956a8 0%, #171c60 88.22%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 2rem;
        font-size: 1.8rem;
        text-align: center;
        margin-top: 2rem;

        @include breakpoint(laptop) {
            line-height: 4rem;
            font-size: 2.2rem;
            text-align: left;
        }

        &__tag {
            padding: 0.625rem 3.5rem;
            background: #fff;
            text-wrap: nowrap;
            margin-top: 1.5rem;
            box-shadow: 0px 16px 32px 0px rgba(25, 86, 168, 0.1);
            display: flex;
            flex-direction: column;
            justify-content: end;
            align-items: center;
            font-size: 1.8rem;
            line-height: initial;
            border-radius: 6.25rem;
            margin-left: auto;
            margin-right: auto;
            width: fit-content;

            @include breakpoint(laptop) {
                display: block;
                font-size: 2rem;
                border-radius: 1rem 6.25rem 6.25rem 1rem;
                padding: 0.625rem 1.5rem;
                margin-left: inherit;
                margin-right: inherit;
            }

            .gradient {
                background: var(--gradient-1, linear-gradient(315deg, #1956a8 0%, #171c60 88.22%));
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            .color {
                background: $ACCENT_40;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
    }

    &__subtitle {
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.875rem; /* 125% */
        text-transform: uppercase;
        color: $SECONDARY_40;
    }

    &__desc {
        color: $SECONDARY_40;
        margin-bottom: 1.25rem;

        p:not(:first-child) {
            position: relative;
            padding-left: 25px;

            &::before {
                position: absolute;
                margin-bottom: 1.25rem;
                content: "";
                height: 20px;
                width: 20px;
                background: url(../../assets/img/arrow-right.svg) no-repeat;
                margin-top: 2px;
                left: 0;
            }
        }
    }

    &__illu {
        display: flex;
        flex-direction: row;
        position: relative;
        align-items: center;

        @include breakpoint(laptop) {
            flex-direction: column;
        }

        img:first-child {
            width: 3.5rem;
            position: absolute;
            margin-left: inherit;
            top: 0;
            right: 10%;

            @include breakpoint(laptop) {
                margin-left: auto;
                position: relative;
            }
        }

        img {
            transform: scale(0.6);

            @include breakpoint(laptop) {
                transform: scale(1);
            }
        }
    }

    &__buttons {
        display: flex;
        gap: 1rem;
        flex-direction: column;
        flex-flow: wrap;
        justify-content: center;

        @include breakpoint(laptop) {
            justify-content: flex-start;
        }
    }
}
