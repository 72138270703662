@import "../../assets/scss/base";

.IDVerify {
    height: 100%;

    &__container {
    }
    &__header {
        display: none;
    }
    .sdk-border-gray-200 {
        border: none;
    }

    .sdk-w-full .sdk-flex:first-child.sdk-items-center {
        display: none;
    }

    img.sdk-absolute {
        display: none;
    }

    .sdk-h-100h {
        height: calc(var(--vh, 1vh) * 60);

        @include breakpoint(mobile) {
            height: inherit;
        }
    }

    .md\:sdk-h-\[70\%\] {
        height: 90%;
    }
}
