@import "../../assets/scss/base";

.addDocumentForm.card {
    height: calc(100vh - 6rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include breakpoint(mobile) {
        border-radius: 0;
        height: 100%;
    }

    .addDocumentForm__buttons {
        margin-bottom: 1rem;
    }

    .addDocumentForm__actions {
        display: flex;
        justify-content: space-between;
    }
}
