@import "../../assets/scss/base";

.modal {
    border-radius: 10px;
    padding: 20px;
    max-width: 500px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1022;

    @include breakpoint(mobile) {
        width: 93%;
    }


    &--info {
        background-color: $PRIMARY_80;
    }

    &--error {
        background-color: $ERROR_70;

        .modal__header__title {
            color: $ERROR_40;
        }
    }

    &--success {
        background-color: $SUCCESS_70;

        .modal__header__title {
            color: $SUCCESS_40;
        }
    }

    &__header {
        @include flexbox(row, space-between, flex-start, 16px);

        margin-bottom: 16px;

        &__title {
            margin-bottom: 0;
        }
    }

    &__body {
        margin-bottom: 24px;

        p {
            margin-bottom: 1rem;
        }
    }

    &__footer {
        @include flexbox(row, flex-end, center, 16px);
    }
}

.modal--backdrop {
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background-color: rgba($PRIMARY_20, 0.6);
    position: fixed;
    z-index: 1021;
}
