@import "../../assets/scss/base";

.DocumentDetails {
    background-color: white;
    border-radius: 10px;
    width: 100%;
    overflow: hidden;
    margin-bottom: 1rem;

    &__header {
        padding: 0.625rem;
        display: flex;
        background: $PRIMARY_70;
        align-items: center;
        color: $PRIMARY_40;
        gap: 1.25rem;
        border-radius: 0.5rem 0.5rem 0rem 0rem;
        position: relative;

        &__left {
            position: relative;
            padding-right: 0.625rem;

            &::before {
                content: "";
                align-self: stretch;
                border-right: 1px solid $PRIMARY_50;
                top: 0;
                right: 0;
                position: absolute;
                height: 100%;
            }
        }

        &__right {
            display: flex;
            gap: 0.625rem;
            align-items: center;
        }
    }

    &__body {
        padding: 0.625rem;

        img {
            display: block;
            max-width: 100%;
            margin: 0 auto 0.9375rem;
        }

        &__buttons {
            @include flexbox(row, center, center, 0.625rem);
        }

        &__title {
            color: $PRIMARY_20;
            font-weight: 700;
            line-height: 1.625rem;
            text-transform: inherit;
            font-size: 1.15rem;
        }

        &__status {
            display: flex;
            gap: .5rem;
            align-items: center;
            margin-bottom: 1rem;
            padding: 0 0.625rem;
            border-radius: .5rem;
            transform: translateX(-0.625rem);

            span {
                flex: 1;
            }
        }
    }
}
