@import "../../assets/scss/base";

.individual {
    tbody tr {
        cursor: pointer;
    }
}

.table {
    color: $PRIMARY_30;
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 2rem;

    thead {
        tr {
            border-bottom: 1px solid $PRIMARY_60;
        }

        th {
            text-transform: uppercase;
            color: $NEUTRAL_30;
            font-size: .75rem;
            text-align: left;
            padding: 10px;
        }
    }

    tbody {
        tr {
            border-bottom: 10px solid transparent;

            &:hover td {
                background-color: $PRIMARY_70;

                &:nth-child(2) {
                    border-radius: 0;
                }
            }
        }

        td {
            padding: 0 10px;
            background-color: #fff;
            transition: .5s ease-in-out;

            .checkbox {
                margin-bottom: 0;
            }

            &:last-child {
                border-radius: 0 8px 8px 0;
            }

            &:first-child {
                border-radius: 8px 0 0 8px;
            }

            .td--nationality {
                @include flexbox(row, center, center);

                font-size: .8125rem;
                text-transform: uppercase;
            }

            .td--type {
                text-transform: uppercase;
                font-family: 'Rubik';
                font-weight: 700;
            }

            .td--creationDate {
                font-size: .8125rem;
                font-family: 'Rubik';
                color: $NEUTRAL_20;
                font-weight: 400;
                text-align: center;
            }

            .td--isBlocked {
                min-width: 12px;
                display: grid;
                place-items: center;
            }
        }
    }

    .checkbox__checkmark {
        margin-right: 0;
    }

    &--S {
        tbody td {
            padding: 0 5px;
        }

        .td--type {
            font-size: .8125rem;
        }
    }

    &--selectable {
        td {
            &:first-child {
                background-color: transparent;
            }

            &:nth-child(2) {
                border-radius: 8px 0 0 8px;
            }
        }
    }
}

.pagination {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: .5rem;
}
