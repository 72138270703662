@import "../../assets/scss/base";

.select-wrapper {
    position: relative;
    width: 100%;

    select {
        display: none;
    }

    &.error {
        .custom-select__trigger {
            border-color: $ERROR_40;
        }
    }

    .custom-select {
        position: relative;

        &__trigger {
            background: #ffffff;
            border: 1px solid $PRIMARY_40;
            border-radius: 5px;
            padding: 8px 12px;
            height: 42px;
            color: $PRIMARY_50;
            font-size: 1rem;
            width: 100%;
            transition: border-color 0.15s ease-in-out;
            font-family: $font-family-body;
            cursor: pointer;
            line-height: 1.5;

            .isFiled {
                color: $PRIMARY_40;
            }

            &::after {
                content: "";
                position: absolute;
                top: 50%;
                right: 8px;
                transform: translateY(-42%);
                width: 0;
                height: 0;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-top: 6px solid $PRIMARY_50;
                pointer-events: none;
            }

            span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 94%;
                display: block;
            }
        }

        &.opened {
            .custom-options {
                display: block;
                opacity: 1;
                width: 100%;
                visibility: visible;
                pointer-events: all;
                transform: translateY(0);
                max-height: 200px;
                overflow-y: auto;
            }
        }
    }

    .custom-options {
        transition-delay: 0.1s;
        position: absolute;
        display: none;
        top: 100%;
        left: 0;
        right: 0;
        min-width: 100%;
        background-color: $PRIMARY_70;
        border-radius: 5px;
        margin-top: 0.5rem;
        transition: all 0.2s ease-in-out;
        pointer-events: none;
        transform: translateY(-1rem);
        z-index: 2;
        padding: 8px;
        visibility: hidden;
        opacity: 0;
    }

    .custom-option {
        color: $PRIMARY_50;
        cursor: pointer;
        margin-bottom: 8px;
        display: block;
        cursor: pointer;
        padding: 8px;
        min-width: 100%;
        border-radius: 5px;
        transition: all 0.2s ease-in-out;

        &:hover {
            color: $PRIMARY_40;
            background-color: $PRIMARY_80;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
    .error-message {
        color: $ERROR_40;
        font-size: 0.875rem;
        margin-top: 0.5rem;
    }
}
