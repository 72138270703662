@import "../../assets/scss/base";

.ConfirmEmail {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 588px;
    text-align: center;
}
