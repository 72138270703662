@import "../../assets/scss/variables";

.DocumentCard {
    border-radius: 0.5rem;
    background-color: $NEUTRAL_100;
    display: inline-flex;
    padding: 0.625rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;
    margin-bottom: 1rem;
    width: 100%;

    &__header {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }

    &__type {
        font-weight: 700;
        line-height: 1.5rem;
        text-transform: uppercase;
    }

    &__date {
        font-size: 0.8125rem;
        letter-spacing: 0.03125rem;

        &__label {
            text-transform: uppercase;
        }
    }
}
