@import "../../assets/scss/variables.scss";

.EmptyContent {
    display: grid;
    place-items: center;
    flex: 1 1 0;
    width: 100%;
    border-radius: 8px;
    background-color: $PRIMARY_70;
    padding: 1.5rem;

    p {
        text-align: center;
        color: $PRIMARY_60;
    }
}
