@import "../../assets/scss/base";

.upload-container {
    text-align: center;
}

.drop-area {
    border: 2px dashed $PRIMARY_40;
    border-radius: 5px;
    padding: 20px;
    cursor: pointer;
    margin-bottom: 10px;
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    label {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        width: 100%;
        height: 100%;
        position: relative;
    }
}

.drop-area:hover {
    background-color: $PRIMARY_70;
}

.image-preview {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.delete-button {
    position: absolute;
    top: -1rem;
    right: -1rem;
}
