/*=============================================
=            Colors            =
=============================================*/

$PRIMARY_20: #0F3465;
$PRIMARY_30: #144586;
$PRIMARY_40: #1956A8;
$PRIMARY_50: #5E89C2;
$PRIMARY_60: #9FC1EF;
$PRIMARY_70: #E8EFFB;
$PRIMARY_80: #F5F9FF;
$SECONDARY_20: #0C0E30;
$SECONDARY_30: #12164D;
$SECONDARY_40: #171C60;
$SECONDARY_50: #454980;
$SECONDARY_60: #8B8EB0;
$SECONDARY_70: #E8E8EF;
$SECONDARY_80: #FAFAFE;
$ACCENT_20: #80441A;
$ACCENT_30: #CC6D29;
$ACCENT_40: #FF8833;
$ACCENT_50: #FFA05C;
$ACCENT_60: #FFC499;
$ACCENT_70: #FFF3EB;
$ERROR_20: #802A13;
$ERROR_30: #CC421E;
$ERROR_40: #FF5326;
$ERROR_50: #FF7551;
$ERROR_60: #FFA993;
$ERROR_70: #FFEEE9;
$SUCCESS_20: #0C763D;
$SUCCESS_30: #12BC61;
$SUCCESS_40: #17EB79;
$SUCCESS_50: #45EF94;
$SUCCESS_60: #8BF5BC;
$SUCCESS_70: #E8FDF2;
$NEUTRAL_20: #152235;
$NEUTRAL_30: #384050;
$NEUTRAL_40: #919AA3;
$NEUTRAL_50: #D3D6DA;
$NEUTRAL_60: #ECEDEF;
$NEUTRAL_70: #F6F7F9;
$NEUTRAL_100: #ffff;

/*=====  End of Colors  ======*/

/*=============================================
=            Spaces            =
=============================================*/

$SPACE_1: 0.25rem;
$SPACE_2: 0.5rem;
$SPACE_3: 0.75rem;
$SPACE_4: 1rem;
$SPACE_5: 1.25rem;
$SPACE_6: 1.5rem;
$SPACE_7: 1.75rem;
$SPACE_8: 2rem;
$SPACE_9: 2.25rem;
$SPACE_10: 2.5rem;

/*=====  End of Spaces  ======*/

/*=============================================
=            Grid            =
=============================================*/
$GRID_COLUMNS: 12;
$GRID_GAP: 1rem;

/*=====  End of Grid  ======*/


/*=============================================
=            SIZE            =
=============================================*/
$SMALL: 0.5rem;
$MEDIUM: 1rem;
$LARGE: 1.7rem;
$LARGE_XL: 1.7rem;
$LARGE_XXL: 4.8rem;

/*=====  End of SIZE  ======*/
