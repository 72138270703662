.card {
    background-color: white;
    border-radius: 1rem;
    width: 100%;
    //    height: 100%;
    padding: 13px;
    box-shadow: 0px 16px 32px 0px rgba(25, 86, 168, 0.1);
    padding: 1.5rem 1.25rem;
    &.card--info {
        box-shadow: inherit;
        border: 1px solid $PRIMARY_40;
        background-color: $PRIMARY_80;
    }
}

.card__promo {
    height: auto;
    background-color: $PRIMARY_40;
    color: #fff;
}
