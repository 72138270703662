@import "../../assets/scss/base";

.HeaderSection {
    border-bottom: 1px solid $PRIMARY_60;
    display: flex;
    padding-right: 0.375rem;
    padding-bottom: .625rem;
    justify-content: space-between;
    align-items: center;
    font-family: $font-family-heading;
    margin-bottom: 1rem;
    height: 45px;
    // See if gap doesnt change anything, its for the progress bar
    gap: 0.35rem;

    &__title {
        font-weight: 700;
        line-height: 1.5rem;
        text-transform: uppercase;
    }

    &__progress-outer {
        background-color: $NEUTRAL_100;
        border-radius: 100px;
        padding: 4px;
        width: 40%;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
    }

    &__progress-container {
        background-color: #D3D6DA;
        border-radius: 100px;
        height: 4px;
        width: 100%;
        position: relative;
        overflow: hidden;
    }

    &__progress-bar {
        background-color: $SUCCESS_30;
        height: 100%;
        border-radius: 100px;
        transition: width 0.3s ease-in-out;
    }

    &__number {
        color: $PRIMARY_50;
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.5rem;
        text-transform: uppercase;

        &-success {
            color: $SUCCESS_30;
        }
    }
}
