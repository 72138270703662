@import "../../assets/scss/base";

.notifications {
    margin-bottom: 1rem;
    height: 300px;
    overflow-x: hidden;
    @include breakpoint(laptop) {
        height: 64vh;
    }
}

.notifications__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $PRIMARY_60;
    padding-bottom: 0.625rem;
    margin-bottom: 0.625rem;
    margin-top: 11px;

    &__title {
        color: $PRIMARY_30;
        font-weight: 700;
    }

    &__count {
        display: flex;
        padding: 0.3125rem 0.375rem 0.3125rem 0.625rem;
        align-items: center;
        gap: 0.625rem;
        background-color: #fff;
        border-radius: 6.25rem;
    }
}

.notifications__button {
    text-decoration: underline;
    cursor: pointer;
    text-align: right;
}
