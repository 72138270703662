@import "../../assets/scss/base";

.input-box {
    &.error {
        textarea {
            border-color: $ERROR_40;
        }
    }

    &.success {
        textarea {
            border-color: $SUCCESS_40;
        }
    }

    textarea {
        background: #FFFFFF;
        border: 1px solid $PRIMARY_40;
        border-radius: 5px;
        padding: 8px 12px;
        margin-bottom: .5rem;
        color: $PRIMARY_40;
        font-size: 1rem;
        width: 100%;
        appearance: none;
        transition: .15s ease-in-out;

        &:focus, &:valid, &:autofill, &:active, &:not(:placeholder-shown) {
            margin-top: 1rem;
        }

        &:focus ~ label,
        &:valid ~ label,
        &:autofill ~ label,
        &:active ~ label,
        &:not(:placeholder-shown) ~ label {
            top: -1rem;
            left: 0;
            color: $PRIMARY_40;
            font-size: .875rem;
        }
    }
}
