@import "../../assets/scss/base";

.PriceCardDetail {
    position: relative;
    top: -30px;
    margin-bottom: -40px;
    h3 {
        font-weight: 900;
        font-family: $font-family-numbers;
    }
    li {
        display: flex;
        margin-bottom: 14px;
        span:first-child {
            margin-right: 8px;
        }
    }

    .without {
        color: $PRIMARY_60;
        .icon-check {
            background-color: $PRIMARY_60;
            display: block;
            width: fit-content;
            height: fit-content;
            padding: 3px;
            border-radius: 50%;
        }
    }

    .within {
        .icon-check {
            background-color: $PRIMARY_40;
            display: block;
            width: fit-content;
            height: fit-content;
            padding: 3px;
            border-radius: 50%;
        }
    }
}
