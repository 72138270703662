.tag {
    padding: 2px 5px;
    border-radius: 2px;
    min-height: 20px;
    line-height: 1rem;
    display: inline-block;
    font-size: .875rem;

    &-icon {
        display: inline-flex;
        gap: .5rem;
        align-items: center;
        padding: 5px;
    }

    &-success {
        background-color: $SUCCESS_70;
        color: $SUCCESS_40;
    }

    &-error {
        background-color: $ERROR_70;
        color: $ERROR_40;
    }

    &-warning {
        background-color: $ACCENT_70;
        color: $ACCENT_40;
    }

    &-info {
        background-color: $PRIMARY_70;
        color: $PRIMARY_40;
    }
}

.notificationDot {
    height: 8px;
    width: 8px;
    border-radius: 8px;
    background-color: $ACCENT_40;
    display: block;
}

.notificationDot-L {
    height: 1.375rem;
    width: 1.375rem;
    line-height: 1.375rem;
    border-radius: 1.375rem;
    background-color: $ACCENT_40;
    display: block;
    text-align: center;
    color: #fff;
    font-weight: 500;
    font-size: 0.8125rem;
}

.flag {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    margin-right: 8px;
}

.tooltip-info {
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;
    border: 1px solid $PRIMARY_40;
}
