@import "../../assets/scss/base";

.RadioButtonPricing {
    width: 100%;
    max-height: 50%;
    min-height: 8rem;
    padding-left: 5%;
    padding-right: 5%;
    background: $PRIMARY_70;
    border-radius: 0.6rem;
    box-shadow: 0px 16px 32px 0px rgba(25, 86, 168, 0.1);

    div:first-child {
        cursor: default;

        div {
            padding-left: 25px;
            span:first-child {
                @include gradient-text-money();
                font-weight: bold;
                font-size: 1.4rem;
                font-family: $font-family-numbers;
                text-transform: uppercase;
            }
        }
        .link:hover {
            color: $ACCENT_30;
            scale: 0.98;
            transition: 0.1s ease;
        }
        .link:focus {
            color: $ACCENT_30;
            scale: 0.98;
            transition: 0.1s ease;
        }
    }

    .price {
        @include gradient-text-money();
        font-weight: 900;
        font-family: $font-family-numbers;
        font-size: 1.8rem;
        cursor: default;
    }
}

.large-radio {
    transform: scale(1.5); /* Augmente la taille de 50% */
    -webkit-transform: scale(1.5); /* Pour les anciens navigateurs WebKit */
    -moz-transform: scale(1.5); /* Pour les anciens navigateurs Firefox */
    -ms-transform: scale(1.5); /* Pour les anciens navigateurs IE */
    -o-transform: scale(1.5); /* Pour les anciens navigateurs Opera */
}

input[type="radio"] {
    cursor: pointer;
    accent-color: orangered;
    transition: 0.3s ease-in-out;
}
input[type="radio"]:checked {
    transform: scale(1.25);
}
