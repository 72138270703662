@import "../../assets/scss/base";

.ToggleIdNumber {
    font-family: 'Rubik';
    font-weight: 500;

    @include flexbox(row, flex-start, center);

    gap: 8px;
}
