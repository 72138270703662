main .VerifyPage {
    height: 100%;
    @include breakpoint(mobile) {
        height: 100%;
        padding: 1rem 0 0 0;
    }

    &__title {
        font-size: 2.5rem !important;

        @include breakpoint(mobile) {
            font-size: 1.5rem;
            text-align: center;
            line-height: initial;
            height: 57px;
        }
    }

    &__row {
        align-items: center;
        height: 100%;
        @include breakpoint(mobile) {
            height: calc(100% - 50px);
            align-items: start;
        }
    }

    &__content {
        height: 100%;
        @include breakpoint(mobile) {
            height: 100%;

            h3 {
                font-size: 1.2rem;
            }
        }
    }

    &__illu {
        @include breakpoint(mobile) {
            display: none;
        }
    }
}
