@import "../../assets/scss/base";

.alert {
    width: 100%;
    border-radius: 8px;
    border-width: 2px;
    border-style: solid;
    min-height: 46px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    gap: 10px;
    margin-bottom: 1rem;

    &__content {
        flex: 1;
        word-break: break-word;
    }

    &-info {
        background-color: $PRIMARY_70;
        color: $PRIMARY_40;
        border-color: $PRIMARY_40;
    }

    &-success {
        background-color: $SUCCESS_70;
        color: $SUCCESS_40;
        border-color: $SUCCESS_40;
    }

    &-error {
        background-color: $ERROR_70;
        color: $ERROR_40;
        border-color: $ERROR_40;
    }

    &-warning {
        background-color: $ACCENT_70;
        color: $ACCENT_40;
        border-color: $ACCENT_40;
    }
}
