@import "../../assets/scss/base";

.checkbox {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 1rem;

    input[type='checkbox'] {
        display: none;
    }

    input[type='checkbox']:checked + .checkbox__checkmark::after {
        opacity: 1;
    }

    &__checkmark {
        flex: none;
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 1.5px solid $PRIMARY_40;
        border-radius: 6px;
        margin-right: 8px;
        background-color: #fff;

        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            background-color: $PRIMARY_40;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zOS4wOTE0IDguMjA3ODVDMzkuNDY1MyA4LjYxNDMgMzkuNDM4OSA5LjI0NjkxIDM5LjAzMjUgOS42MjA4NEwxNC4wNjI1IDMyLjU5MzJDMTMuNjY4IDMyLjk1NjIgMTMuMDU3NCAzMi45NDM1IDEyLjY3ODMgMzIuNTY0NEwxLjcwNzIgMjEuNTkzMkMxLjMxNjY3IDIxLjIwMjcgMS4zMTY2NyAyMC41Njk2IDEuNzA3MiAyMC4xNzlMMy4xMjE0MSAxOC43NjQ4QzMuNTExOTQgMTguMzc0MyA0LjE0NTEgMTguMzc0MyA0LjUzNTYzIDE4Ljc2NDhMMTIuNzkzNyAyNy4wMjI5QzEzLjE3MjggMjcuNDAyIDEzLjc4MzQgMjcuNDE0NyAxNC4xNzc5IDI3LjA1MThMMzYuMzI0MyA2LjY3NzEyQzM2LjczMDcgNi4zMDMxOSAzNy4zNjMzIDYuMzI5NTUgMzcuNzM3MiA2LjczNTk5TDM5LjA5MTQgOC4yMDc4NVoiIGZpbGw9IiNmZmZmIiAvPgo8L3N2Zz4K");
            background-position: center;
            background-repeat: no-repeat;
            background-size: 12px;
            border-radius: 2px;
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
        }
    }
}
