@import "../../assets/scss/base";

.Toast {
    /* Ajoutez vos styles ici */
    position: fixed;
    left: 63%;
    transform: translateX(-63%);
    border-radius: 8px;
    border-width: 2px;
    border-style: solid;
    min-height: 46px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    gap: 10px;
    animation: slideIn .25s ease-in-out;
    animation-fill-mode: forwards;

    &.hide {
        animation: slideOut .25s ease-in-out;
        animation-fill-mode: forwards;
    }

    @keyframes slideIn {
        0% {
            bottom: -100%;
        }

        100% {
            bottom: 50px;
        }
    }

    @keyframes slideOut {
        0% {
            bottom: 0;
        }

        100% {
            bottom: -100%;
        }
    }

    &__content {
        flex: 1;
        word-break: break-word;
    }

    &-info {
        background-color: $PRIMARY_70;
        color: $PRIMARY_40;
        border-color: $PRIMARY_40;
    }

    &-success {
        background-color: $SUCCESS_70;
        color: $SUCCESS_40;
        border-color: $SUCCESS_40;
    }

    &-error {
        background-color: $ERROR_70;
        color: $ERROR_40;
        border-color: $ERROR_40;
    }

    &-warning {
        background-color: $ACCENT_70;
        color: $ACCENT_40;
        border-color: $ACCENT_40;
    }
}
