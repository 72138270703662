@import "../../assets/scss/base";

.StatusAccompagnement {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    align-self: stretch;
    margin-bottom: 1.875rem;

    &__step {
        display: flex;
        padding: 0.625rem;
        align-items: center;
        border-radius: 0.5rem;
        border: 2px solid $PRIMARY_40;
        background: var(--Neutral-100, #FFF);
        gap: 0.625rem;
        color: $PRIMARY_40;
        height: 3.75rem;

        span {
            font-family: Raleway;
            font-size: 1rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.625rem;
        }

        &__success {
            color: $SUCCESS_30;
            border-color: $SUCCESS_30;
        }

        &__disabled {
            background-color: $PRIMARY_70;
            color: $PRIMARY_60;
            border: 0;
        }
    }
}
