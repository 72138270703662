.IndicatifPhone {
    flex: 1;
    margin-bottom: 0.5rem;

    /* Ajoutez vos styles ici */
    img {
        height: 20px;
        width: 20px;
        border-radius: 20px;
        margin-right: 1rem;
    }

    .custom-options {
        min-width: 300px;
    }

    .custom-option {
        display: flex;
        align-items: center;
    }
}
