@import "../../assets/scss/base";

.aside {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include breakpoint(tablet) {
        width: 25%;
        height: 100%;
        margin-bottom: 0;
        &.collapsed {
            width: auto;
        }
    }

    &.collapsed &__button {
        width: initial;
    }

    &__header {
        border-bottom: 1px solid $PRIMARY_30;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
    }
    &--mobile {
        margin-bottom: 1rem;
        .navbar-brand {
            margin-bottom: 0 !important;
            border-bottom: none !important;
            width: 150px;
        }

        input {
            display: none;
        }

        input + label {
            position: fixed;
            top: 40px;
            right: 40px;
            height: 20px;
            width: 15px;
            z-index: 5;

            span {
                position: absolute;
                width: 100%;
                height: 2px;
                top: 50%;
                margin-top: -1px;
                left: 0;
                display: block;
                background: white;
                transition: 0.5s;
            }

            span:first-child {
                top: 3px;
            }

            span:last-child {
                top: 16px;
            }
        }

        label:hover {
            cursor: pointer;
        }

        input:checked + label {
            span {
                opacity: 0;
                top: 50%;
            }

            span:first-child {
                opacity: 1;
                transform: rotate(405deg);
            }

            span:last-child {
                opacity: 1;
                transform: rotate(-405deg);
            }
        }

        input ~ .nav {
            background: linear-gradient(278.49deg, $SECONDARY_40 0%, $PRIMARY_40 100%) no-repeat center center fixed;
            position: fixed;
            top: 90px;
            left: 0;
            width: 100%;
            z-index: 3;
            transition: 0.5s;
            transition-delay: 0.5s;
            overflow: hidden;

            > ul {
                // text-align: center;
                // position: absolute;
                // top: 35%;
                // left: 20%;
                // right: 20%;

                > li {
                    opacity: 0;
                    transition: 0.5s;
                    transition-delay: 0s;
                }
            }
        }

        input:checked ~ .nav {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: calc(100% - 89.8px);
            transition-delay: 0s;
            padding: 0 1rem;

            > ul {
                > li {
                    opacity: 1;
                    transition-delay: 0.5s;
                }
            }
        }
    }

    .navbar-brand {
        display: block;

        img {
            max-width: 150px;
            max-height: 57px;
        }
    }

    &__profile {
        padding-top: 1rem;
        border-top: 1px solid $PRIMARY_30;
    }

    &__item {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 1rem;
        text-align: left;
        flex-direction: row;
        gap: 1rem;
        justify-content: flex-start;
        text-align: left;

        span,
        &__content {
            cursor: pointer;
        }
    }

    ul {
        display: flex;
        color: white;
        gap: 0.5rem;
        width: 100%;
        flex-direction: column;

        &:last-child() {
            border-top: 1px solid $PRIMARY_30;
        }

        li {
            list-style: none;
            cursor: pointer;
            border-radius: 0.5rem;
            width: 100%;
            display: flex;
            justify-content: center;

            &:hover {
                cursor: pointer;
                background-color: rgba($color: #ffff, $alpha: 0.1);
            }

            &.active {
                background-color: rgba($color: #ffff, $alpha: 0.2);
            }

            .aside__button {
                font-weight: 700;
                line-height: 1.25rem;
                letter-spacing: 0.03125rem;
                text-transform: uppercase;
                color: white;
                text-decoration: none;
                width: 100%;
                display: block;
                padding: 0.625rem 1rem;
                cursor: pointer;

                p {
                    text-transform: initial;
                    font-weight: normal;
                    cursor: pointer;
                }
            }
        }
    }
}
