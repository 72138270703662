// Initial invisible scrollbar for Webkit browsers
body::-webkit-scrollbar {
    width: 12px;
}

body.inactive::-webkit-scrollbar-thumb {
    background: transparent;
}

body.active::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 6px;
}

// Initial invisible scrollbar for Firefox
body {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
}

body.active {
    scrollbar-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.1);
}
